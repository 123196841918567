import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Irrigation Engineering Practices`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1P8c6_H0z1xhZiOkDmdp7EmIUDwrc7SL3"
      }}>{`Download: Irrigation and Hydraulic Structures Theory, Design & Practice By Dr. Iqbal Ali`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1QXbLAjrJU6v29RgiIrppelZ3UVaiTjh2"
      }}>{`Download: Hydraulic Structures 4th ed by P. Novak, A.I.B Moffat, C. Nullari, R. Narayanan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1kbrcYtMgKRJR99h5sg6ve2ApBSvOLveX"
      }}>{`Download: Irrigation engineering and hydraulic structures by Santosh Kumar Garg`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1n488wm06WVznl5HJNt87U3UgJSgRB-PP"
      }}>{`Download: Irrigation engineering by NN Basak`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=19GpOyDMVIIltR8bg2mtuSJ1OhGbme0vG"
      }}>{`Download: Irrigation and water resources engineering by G.L. Asawa`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1U5qDFjOtFVJ9s47xTa0J8vrY9OCxJYco"
      }}>{`Download: FAO Irrigation and drainage paper no. 56 Crop evapotranspiration`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1WecYTxUUpTAoOudmsudd9r4pclZ8PU2T"
      }}>{`Download: FAO Irrigation and drainage paper no. 24 Crop water requirements`}</a></p>
    <h2>{`Lecture slides`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-1-2-introduction.pptx"
      }}>{`Lecture-1-2 introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-2-crop-water-requirments-mod.ppt"
      }}>{`Lecture-2-crop water requirments-mod`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-3-4-5-ETo.pptx"
      }}>{`Lecture-3-4-5 ETo`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-6-Crop-Coefficients.pptx"
      }}>{`Lecture-6-Crop Coefficients`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-7-Scheduling-and-warabandi.pptx"
      }}>{`Lecture-7-Scheduling and warabandi`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-8-Losses-in-Irrigation-System.pptx"
      }}>{`Lecture-8 Losses in Irrigation System`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-9-Onfarm-Water-management.pdf"
      }}>{`Lecture-9 Onfarm Water management`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-10-11-12-Irrigation-methodSprinking-System.pptx"
      }}>{`Lecture-10-11, 12 Irrigation method+Sprinking System`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-13-Performance-Indicators.pptx"
      }}>{`Lecture-13-Performance Indicators`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Lecture-14-Systems-Approach-to-Water-Resources.pptx"
      }}>{`Lecture-14 Systems Approach to Water Resources`}</a></p>
    <h1>{`Notes`}</h1>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/15.-Irrigation-engineering-principles-Indias-irrigation-needs-and-strategies-for-developments.pdf"
      }}>{`15. Irrigation engineering principles (India’s irrigation needs and strategies for developments)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/16.-Irrigation-engineering-principles-Soil-water-plant-relationships.pdf"
      }}>{`16. Irrigation engineering principles (Soil water plant relationships)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/17.-Irrigation-engineering-principles-Estimating-irrigation-demands.pdf"
      }}>{`17. Irrigation engineering principles (Estimating irrigation demands)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/18.-Irrigation-engineering-principles-Types-of-irrigation-schemes-and-methods-for-field-water-applications.pdf"
      }}>{`18. Irrigation engineering principles (Types of irrigation schemes and methods for field water applications)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/19.-Irrigation-engineering-principlesTraditional-water-systems-and-minor-water-schemes.pdf"
      }}>{`19. Irrigation engineering principles(Traditional water systems and minor water schemes)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/20.-Irrigation-engineering-principles-Canal-systems-for-major-and-minor-irrigation-schemes.pdf"
      }}>{`20. Irrigation engineering principles (Canal systems for major and minor irrigation schemes)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/21.-Irrigation-engineering-principles-Design-of-irrigation-canals.pdf"
      }}>{`21. Irrigation engineering principles (Design of irrigation canals)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/22.-Irrigation-engineering-principles-Conveyance-structures-for-canal-flows.pdf"
      }}>{`22. Irrigation engineering principles (Conveyance structures for canal flows)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/23.-Irrigation-engineering-principles-Regulating-structures-for-canal-flows.pdf"
      }}>{`23. Irrigation engineering principles (Regulating structures for canal flows)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/24.-Irrigation-engineering-principlesDistribution-and-measurement-structures-for-canal-flows.pdf"
      }}>{`24. Irrigation engineering principles(Distribution and measurement structures for canal flows)`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      